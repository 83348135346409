/*
I. Permission
0. ADMIN_CREATE_ISSUANCE
1. ADMIN_UPDATE_ISSUANCE
2. ADMIN_SUBMIT_ISSUANCE
3. ADMIN_APPROVE_ISSUANCE_FIRST
4. ADMIN_REJECT_ISSUANCE_FIRST
5. ADMIN_PUBLISH_ISSUANCE
6. ADMIN_APPROVE_ISSUANCE_SECOND
7. ADMIN_REJECT_ISSUANCE_SECOND
*/

export enum ISSUANCE_PERMISSION {
  ADMIN_PROJECT_CREATE, // 0
  ADMIN_PROJECT_UPDATE, // 1
  ADMIN_PROJECT_SUBMIT, // 2
  ADMIN_PROJECT_APPROVE, // 3
  ADMIN_PROJECT_REJECT, // 4
  ADMIN_PROJECT_PUBLISH, // 5
  ADMIN_PROJECT_REVIEW_R1, // 6
  ADMIN_PROJECT_REVIEW_R2, // 7
  ADMIN_PROJECT_REWORK, // 8
}
/*
 State of issuance
    0 NEW
    1. PENDING1
    2. REJECT1
    3. PENDING2
    4. REJECT2
    5. APPROVED
    6. PUBLISH
*/
export enum ISSUANCE_STATE {
  NEW, // 0
  PENDING1, // 1
  REJECT1, // 2
  PENDING2, // 3
  REJECT2, // 4
  APPROVED, // 5
  PUBLISH, // 6
  PUBLISHING, // 7
  FAILED,
}

/*
I. Issuer onboarding permission
0. ADMIN_USER_APPROVE
1. ADMIN_USER_REJECT
2. ADMIN_APPLICANT_READ
3. ADMIN_USER_UPDATE
4. ADMIN_USER_CREATE
5. ADMIN_USER_READ
6. ADMIN_USER_MANAGE
7. ADMIN_USER_SEND_LINK_REGISTER
*/

export enum CREATE_ISSUER_PERMISSION {
  ADMIN_USER_APPROVE, // 0
  ADMIN_USER_REJECT, // 1
  ADMIN_APPLICANT_READ, // 2
  ADMIN_USER_UPDATE, // 3
  ADMIN_USER_CREATE, // 4
  ADMIN_USER_READ, // 5
  ADMIN_USER_MANAGE, // 6
  ADMIN_USER_SEND_LINK_REGISTER, // 7
  ADMIN_USER_SUBMIT, // 8
  ADMIN_USER_REWORK, // 9
}
/*
 State of issuance
0. NEW
1. ACCEPTED
2. REJECTED
*/
export enum CREATE_ISSUER_STATE {
  NEW,
  ACCEPTED,
  REJECTED,
  PENDING,
  INACTIVE,
}

export enum CREATE_INVESTOR_PERMISSION {
  ADMIN_INVESTOR_CREATE,
  ADMIN_INVESTOR_UPDATE,
  ADMIN_INVESTOR_APPROVE,
  ADMIN_INVESTOR_REJECT,
  ADMIN_INVESTOR_SUBMIT,
  ADMIN_INVESTOR_SEND_LINK_REGISTER,
  ADMIN_INVESTOR_CLOSE,
  ADMIN_INVESTOR_REACTIVATION_SUBMIT,
  ADMIN_INVESTOR_REACTIVATION_APPROVE,
  ADMIN_INVESTOR_REACTIVATION_REJECT,
}

export enum CREATE_AUTHORIZED_PERSON_PERMISSION {
  ADMIN_AUTHORIZED_PERSON_CREATE,
  ADMIN_AUTHORIZED_PERSON_UPDATE,
  ADMIN_AUTHORIZED_PERSON_APPROVE,
  ADMIN_AUTHORIZED_PERSON_REJECT,
  ADMIN_AUTHORIZED_PERSON_SUBMIT,
  ADMIN_AUTHORIZED_PERSON_CLOSE,
  ADMIN_AUTHORIZED_PERSON_REACTIVATION_SUBMIT,
  ADMIN_AUTHORIZED_PERSON_REACTIVATION_APPROVE,
  ADMIN_AUTHORIZED_PERSON_REACTIVATION_REJECT,
}

// export enum SECONDARY_PERMISSION {
//   ADMIN_SECONDARY_ACTION_MANAGE, 0
//   ADMIN_SECONDARY_ACTION_CREATE, 1
//   ADMIN_SECONDARY_ACTION_UPDATE, 2
//   ADMIN_SECONDARY_ACTION_SUBMIT, 3
//   ADMIN_SECONDARY_ACTION_APPROVAL, 4
//   ADMIN_SECONDARY_ACTION_REJECT, 5
//   ADMIN_SECONDARY_ACTION_REWORK, 6
//   ADMIN_SECONDARY_ACTION_CANCEL, 7
//   ADMIN_SECONDARY_ACTION_EXECUTE, 8
// }
/*
 State of issuance
0. NEW
1. ACCEPTED
2. REJECTED
*/
export enum CREATE_INVESTOR_STATE {
  NEW,
  ACCEPTED,
  REJECTED,
  PENDING,
  INACTIVE,
  DORMANT,
  CLOSED,
  PENDING_REACTIVATION,
}

export enum CREATE_EXTENSION_REQUEST_PERMISSION {
  ADMIN_EXTENSION_REQUEST_CREATE, // 0
  ADMIN_EXTENSION_REQUEST_UPDATE, // 1
  ADMIN_EXTENSION_REQUEST_DELETE, // 2
  ADMIN_EXTENSION_REQUEST_APPROVE, // 3
  ADMIN_EXTENSION_REQUEST_REJECT, // 4
}

export enum CREATE_FINANCE_PERMISSION {
  ADMIN_FINANCE_VERIFY_BANK_ACCOUNT,
  ADMIN_FINANCE_CREATE,
  ADMIN_FINANCE_MANAGE,
  ADMIN_FINANCE_READ,
  ADMIN_FINANCE_SUBMIT,
  ADMIN_FINANCE_UPDATE,
}

export enum DORMANTED_INVESTOR_PERMISSION {
  ADMIN_DORMANTED_INVESTOR_EDIT,
  ADMIN_DORMANTED_INVESTOR_ACTIVE,
  ADMIN_DORMANTED_INVESTOR_INACTIVE,
}
export enum DORMANTED_AUTHORIZED_PERMISSION {
  ADMIN_DORMANTED_AUTHORIZED_PERSON_EDIT,
  ADMIN_DORMANTED_AUTHORIZED_PERSON_ACTIVE,
  ADMIN_DORMANTED_AUTHORIZED_PERSON_INACTIVE,
}

export const OMNIBUS_PERMISSION = {
  ADMIN_OMNIBUS_READ: 'ADMIN_OMNIBUS_READ',
  ADMIN_OMNIBUS_CREATE: 'ADMIN_OMNIBUS_CREATE',
  ADMIN_OMNIBUS_UPDATE: 'ADMIN_OMNIBUS_UPDATE',
  ADMIN_OMNIBUS_SUBMIT: 'ADMIN_OMNIBUS_SUBMIT',
  ADMIN_OMNIBUS_APPROVE: 'ADMIN_OMNIBUS_APPROVE',
  ADMIN_OMNIBUS_REJECT: 'ADMIN_OMNIBUS_REJECT',
  ADMIN_OMNIBUS_REWORK: 'ADMIN_OMNIBUS_REWORK',
  ADMIN_OMNIBUS_INACTIVE: 'ADMIN_OMNIBUS_INACTIVE',
  ADMIN_OMNIBUS_ACTIVE: 'ADMIN_OMNIBUS_ACTIVE',
  ADMIN_OMNIBUS_ACTOR_UPDATE: 'ADMIN_OMNIBUS_ACTOR_UPDATE',
  ADMIN_OMNIBUS_ACTOR_CREATE: 'ADMIN_OMNIBUS_ACTOR_CREATE',
};
export const REFERRAL_PERMISSION = {
  ADMIN_REFERRAL_PROMOTION_CREATE: 'ADMIN_REFERRAL_PROMOTION_CREATE',
  ADMIN_REFERRAL_PROMOTION_MANAGE: 'ADMIN_REFERRAL_PROMOTION_MANAGE',
  ADMIN_REFERRAL_PROMOTION_READ: 'ADMIN_REFERRAL_PROMOTION_READ',
  ADMIN_REFERRAL_PROMOTION_UPDATE: 'ADMIN_REFERRAL_PROMOTION_UPDATE',
  ADMIN_REFERRAL_PROMOTION_START: 'ADMIN_REFERRAL_PROMOTION_START',
  ADMIN_REFERRAL_PROMOTION_END: 'ADMIN_REFERRAL_PROMOTION_END',
  ADMIN_REFERRAL_PROMOTION_DOWNLOAD_USER: 'ADMIN_REFERRAL_PROMOTION_DOWNLOAD_USER',
  ADMIN_REFERRAL_EMAIL_CREATE: 'ADMIN_REFERRAL_EMAIL_CREATE',
  ADMIN_REFERRAL_EMAIL_MANAGE: 'ADMIN_REFERRAL_EMAIL_MANAGE',
  ADMIN_REFERRAL_EMAIL_READ: 'ADMIN_REFERRAL_EMAIL_READ',
  ADMIN_REFERRAL_EMAIL_UPDATE: 'ADMIN_REFERRAL_EMAIL_UPDATE',
  ADMIN_REFERRAL_EMAIL_SUBMIT: 'ADMIN_REFERRAL_EMAIL_SUBMIT',
  ADMIN_REFERRAL_EMAIL_RESEND: 'ADMIN_REFERRAL_EMAIL_RESEND',
};

export const ISSUER_PERMISSION = {
  ADMIN_ISSUER2_SUBMIT: 'ADMIN_ISSUER2_SUBMIT',
  ADMIN_ISSUER2_APPROVE: 'ADMIN_ISSUER2_APPROVE',
  ADMIN_ISSUER2_REJECT: 'ADMIN_ISSUER2_REJECT',
  ADMIN_ISSUER2_REWORK: 'ADMIN_ISSUER2_REWORK',
  ADMIN_ISSUER2_INACTIVE: 'ADMIN_ISSUER2_INACTIVE',
  ADMIN_ISSUER2_ACTIVE: 'ADMIN_ISSUER2_ACTIVE',
  ADMIN_ISSUER2_READ: 'ADMIN_ISSUER2_READ',
  ADMIN_ISSUER2_UPDATE: 'ADMIN_ISSUER2_UPDATE',
  ADMIN_ISSUER2_CREATE: 'ADMIN_ISSUER2_CREATE',
  ADMIN_ISSUER2_ACTOR_CREATE: 'ADMIN_ISSUER2_ACTOR_CREATE',
  ADMIN_ISSUER2_ACTOR_UPDATE: 'ADMIN_ISSUER2_ACTOR_UPDATE',
  ADMIN_ISSUER2_ACTOR_READ: 'ADMIN_ISSUER2_ACTOR_READ',
  ADMIN_ISSUER2_MANAGE: 'ADMIN_ISSUER2_MANAGE',
  ADMIN_ISSUER2_ACTOR_ACTIVE: 'ADMIN_ISSUER2_ACTOR_ACTIVE',
  ADMIN_ISSUER2_ACTOR_INACTIVE: 'ADMIN_ISSUER2_ACTOR_INACTIVE',
  ADMIN_ISSUER2_ACTOR_APPROVE: 'ADMIN_ISSUER2_ACTOR_APPROVE',
  ADMIN_ISSUER2_ACTOR_REJECT: 'ADMIN_ISSUER2_ACTOR_REJECT',
  ADMIN_ISSUER2_ACTOR_REWORK: 'ADMIN_ISSUER2_ACTOR_REWORK',
  ADMIN_ISSUER_BANK_VERIFY: 'ADMIN_ISSUER_BANK_VERIFY',
  ADMIN_ISSUER_BANK_UPDATE: 'ADMIN_ISSUER_BANK_UPDATE',
};

export const LATE_PAYMENT_PERMISSION = {
  ADMIN_LATE_PAYMENT_CHARGE_CREATE_ANNOUNCEMENT: 'ADMIN_LATE_PAYMENT_CHARGE_CREATE_ANNOUNCEMENT',
  ADMIN_LATE_PAYMENT_CHARGE_READ_ACTIVITY_LOG: 'ADMIN_LATE_PAYMENT_CHARGE_READ_ACTIVITY_LOG',
  ADMIN_LATE_PAYMENT_CHARGE_CREATE: 'ADMIN_LATE_PAYMENT_CHARGE_CREATE',
  ADMIN_LATE_PAYMENT_CHARGE_MANAGE: 'ADMIN_LATE_PAYMENT_CHARGE_MANAGE',
  ADMIN_LATE_PAYMENT_CHARGE_UPDATE: 'ADMIN_LATE_PAYMENT_CHARGE_UPDATE',
  ADMIN_LATE_PAYMENT_CHARGE_SUBMIT: 'ADMIN_LATE_PAYMENT_CHARGE_SUBMIT',
  ADMIN_LATE_PAYMENT_CHARGE_APPROVE: 'ADMIN_LATE_PAYMENT_CHARGE_APPROVE',
  ADMIN_LATE_PAYMENT_CHARGE_REJECT: 'ADMIN_LATE_PAYMENT_CHARGE_REJECT',
  ADMIN_LATE_PAYMENT_CHARGE_REWORK: 'ADMIN_LATE_PAYMENT_CHARGE_REWORK',
  ADMIN_LATE_PAYMENT_CHARGE_EXECUTE: 'ADMIN_LATE_PAYMENT_CHARGE_EXECUTE',
  ADMIN_LATE_PAYMENT_CHARGE_CANCEL: 'ADMIN_LATE_PAYMENT_CHARGE_CANCEL',
};

export const SECONDARY_PERMISSION = {
  ADMIN_SECONDARY_ACTION_MANAGE: 'ADMIN_SECONDARY_ACTION_MANAGE',
  ADMIN_SECONDARY_ACTION_CREATE: 'ADMIN_SECONDARY_ACTION_CREATE',
  ADMIN_SECONDARY_ACTION_UPDATE: 'ADMIN_SECONDARY_ACTION_UPDATE',
  ADMIN_SECONDARY_ACTION_SUBMIT: 'ADMIN_SECONDARY_ACTION_SUBMIT',
  ADMIN_SECONDARY_ACTION_APPROVAL: 'ADMIN_SECONDARY_ACTION_APPROVAL',
  ADMIN_SECONDARY_ACTION_REJECT: 'ADMIN_SECONDARY_ACTION_REJECT',
  ADMIN_SECONDARY_ACTION_REWORK: 'ADMIN_SECONDARY_ACTION_REWORK',
  ADMIN_SECONDARY_ACTION_CANCEL: 'ADMIN_SECONDARY_ACTION_CANCEL',
  ADMIN_SECONDARY_ACTION_EXECUTE: 'ADMIN_SECONDARY_ACTION_EXECUTE',
  ADMIN_SECONDARY_ACTION_COMPLETE: 'ADMIN_SECONDARY_ACTION_COMPLETE',
  ADMIN_TOKEN_HOLDER_MEETING_CREATE_ANNOUNCEMENT: 'ADMIN_TOKEN_HOLDER_MEETING_CREATE_ANNOUNCEMENT',
  ADMIN_TOKEN_HOLDER_MEETING_READ_ACTIVITY_LOG: 'ADMIN_TOKEN_HOLDER_MEETING_READ_ACTIVITY_LOG',
};

export const CUMULATIVE_DIVIDEND_PERMISSION = {
  ADMIN_CUMULATIVE_DIVIDEND_CREATE: 'ADMIN_CUMULATIVE_DIVIDEND_CREATE',
  ADMIN_CUMULATIVE_DIVIDEND_READ: 'ADMIN_CUMULATIVE_DIVIDEND_READ',
  ADMIN_CUMULATIVE_DIVIDEND_SUBMIT: 'ADMIN_CUMULATIVE_DIVIDEND_SUBMIT',
  ADMIN_CUMULATIVE_DIVIDEND_APPROVE: 'ADMIN_CUMULATIVE_DIVIDEND_APPROVE',
  ADMIN_CUMULATIVE_DIVIDEND_REJECT: 'ADMIN_CUMULATIVE_DIVIDEND_REJECT',
  ADMIN_CUMULATIVE_DIVIDEND_REWORK: 'ADMIN_CUMULATIVE_DIVIDEND_REWORK',
  ADMIN_CUMULATIVE_DIVIDEND_SCHEDULE: 'ADMIN_CUMULATIVE_DIVIDEND_SCHEDULE',
};

export const TOKEN_DELISTING_PERMISSION = {
  ADMIN_TOKEN_DELISTING_APPROVE: 'ADMIN_TOKEN_DELISTING_APPROVE',
  ADMIN_TOKEN_DELISTING_CANCEL: 'ADMIN_TOKEN_DELISTING_CANCEL',
  ADMIN_TOKEN_DELISTING_CREATE: 'ADMIN_TOKEN_DELISTING_CREATE',
  ADMIN_TOKEN_DELISTING_GET: 'ADMIN_TOKEN_DELISTING_GET',
  ADMIN_TOKEN_DELISTING_REJECT: 'ADMIN_TOKEN_DELISTING_REJECT',
  ADMIN_TOKEN_DELISTING_REWORK: 'ADMIN_TOKEN_DELISTING_REWORK',
  ADMIN_TOKEN_DELISTING_SUBMIT: 'ADMIN_TOKEN_DELISTING_SUBMIT',
  ADMIN_TOKEN_DELISTING_UPDATE: 'ADMIN_TOKEN_DELISTING_UPDATE',
  ADMIN_TOKEN_DELISTING_TRANSFER: 'ADMIN_TOKEN_DELISTING_TRANSFER',
  ADMIN_TOKEN_DELISTING_RETRANSFER: 'ADMIN_TOKEN_DELISTING_RETRANSFER',
};
export const EVENT_OF_DEFAULT_PERMISSION = {
  ADMIN_EVENT_OF_DEFAULT_APPROVE: 'ADMIN_EVENT_OF_DEFAULT_APPROVE',
  ADMIN_EVENT_OF_DEFAULT_CANCEL: 'ADMIN_EVENT_OF_DEFAULT_CANCEL',
  ADMIN_EVENT_OF_DEFAULT_CREATE: 'ADMIN_EVENT_OF_DEFAULT_CREATE',
  ADMIN_EVENT_OF_DEFAULT_FREEZE: 'ADMIN_EVENT_OF_DEFAULT_FREEZE',
  ADMIN_EVENT_OF_DEFAULT_GET: 'ADMIN_EVENT_OF_DEFAULT_GET',
  ADMIN_EVENT_OF_DEFAULT_REJECT: 'ADMIN_EVENT_OF_DEFAULT_REJECT',
  ADMIN_EVENT_OF_DEFAULT_REWORK: 'ADMIN_EVENT_OF_DEFAULT_REWORK',
  ADMIN_EVENT_OF_DEFAULT_SUBMIT: 'ADMIN_EVENT_OF_DEFAULT_SUBMIT',
  ADMIN_EVENT_OF_DEFAULT_UNFREEZE: 'ADMIN_EVENT_OF_DEFAULT_UNFREEZE',
  ADMIN_EVENT_OF_DEFAULT_UPDATE: 'ADMIN_EVENT_OF_DEFAULT_UPDATE',
};

export const RIGHT_ISSUE_SUBMISSION = {
  ADMIN_SUBSCRIPTION_SECONDARY_ACTION_CREATE: 'ADMIN_SUBSCRIPTION_SECONDARY_ACTION_CREATE',
  ADMIN_SUBSCRIPTION_SECONDARY_ACTION_UPDATE: 'ADMIN_SUBSCRIPTION_SECONDARY_ACTION_UPDATE',
  ADMIN_SUBSCRIPTION_SECONDARY_ACTION_CANCEL: 'ADMIN_SUBSCRIPTION_SECONDARY_ACTION_CANCEL',
  ADMIN_ALLOCATION_CONFIRMATION_VIEW: 'ADMIN_ALLOCATION_CONFIRMATION_VIEW',
};

export const NON_CUMULATIVE_PERMISSION = {
  ADMIN_NON_CUMULATIVE_REDEMPTION_CREATE_ANNOUNCEMENT: 'ADMIN_NON_CUMULATIVE_REDEMPTION_CREATE_ANNOUNCEMENT',
  ADMIN_NON_CUMULATIVE_REDEMPTION_READ_ACTIVITY_LOG: 'ADMIN_NON_CUMULATIVE_REDEMPTION_READ_ACTIVITY_LOG',
  ADMIN_NON_CUMULATIVE_REDEMPTION_CREATE: 'ADMIN_NON_CUMULATIVE_REDEMPTION_CREATE',
  ADMIN_NON_CUMULATIVE_REDEMPTION_MANAGE: 'ADMIN_NON_CUMULATIVE_REDEMPTION_MANAGE',
  ADMIN_NON_CUMULATIVE_REDEMPTION_UPDATE: 'ADMIN_NON_CUMULATIVE_REDEMPTION_UPDATE',
  ADMIN_NON_CUMULATIVE_REDEMPTION_SUBMIT_TO_APPROVE: 'ADMIN_NON_CUMULATIVE_REDEMPTION_SUBMIT_TO_APPROVE',
  ADMIN_NON_CUMULATIVE_REDEMPTION_APPROVE: 'ADMIN_NON_CUMULATIVE_REDEMPTION_APPROVE',
  ADMIN_NON_CUMULATIVE_REDEMPTION_REJECT: 'ADMIN_NON_CUMULATIVE_REDEMPTION_REJECT',
  ADMIN_NON_CUMULATIVE_REDEMPTION_REWORK: 'ADMIN_NON_CUMULATIVE_REDEMPTION_REWORK',
  ADMIN_NON_CUMULATIVE_REDEMPTION_EXECUTE: 'ADMIN_NON_CUMULATIVE_REDEMPTION_EXECUTE',
  ADMIN_NON_CUMULATIVE_REDEMPTION_CANCEL: 'ADMIN_NON_CUMULATIVE_REDEMPTION_CANCEL',
};

export const CUMULATIVE_REDEMPTION_PERMISSION = {
  ADMIN_CUMULATIVE_REDEMPTION_CREATE: 'ADMIN_CUMULATIVE_REDEMPTION_CREATE',
  ADMIN_CUMULATIVE_REDEMPTION_UPDATE: 'ADMIN_CUMULATIVE_REDEMPTION_UPDATE',
  ADMIN_CUMULATIVE_REDEMPTION_SUBMIT_FOR_APPROVE: 'ADMIN_CUMULATIVE_REDEMPTION_SUBMIT_FOR_APPROVE',
  ADMIN_CUMULATIVE_REDEMPTION_APPROVE: 'ADMIN_CUMULATIVE_REDEMPTION_APPROVE',
  ADMIN_CUMULATIVE_REDEMPTION_REJECT: 'ADMIN_CUMULATIVE_REDEMPTION_REJECT',
  ADMIN_CUMULATIVE_REDEMPTION_REWORK: 'ADMIN_CUMULATIVE_REDEMPTION_REWORK',
  ADMIN_CUMULATIVE_REDEMPTION_EXECUTE: 'ADMIN_CUMULATIVE_REDEMPTION_EXECUTE',
  ADMIN_CUMULATIVE_REDEMPTION_CANCEL: 'ADMIN_CUMULATIVE_REDEMPTION_CANCEL',
  ADMIN_CUMULATIVE_REDEMPTION_RETRY: 'ADMIN_CUMULATIVE_REDEMPTION_RETRY',
};

export const DATE_CONSTANTS = {
  MONTH_DAY_YEAR: 'MM-DD-YYYY',
  SHORT: 'DD/MM/YYYY HH:mm',
  LONG: 'DD MMM YYYY HH:mm',
  LONG_SS: 'DD MMM YYYY HH:mm:ss',
  LONG_F_SS: 'DD/MM/YYYY HH:mm:ss',
  YMD: 'YYYY-MM-DD',
  DMY: 'DD-MM-YYYY',
  HOUR_MINUTE: 'HH:mm',
  MDoY: 'MMM Do YY',
  DMY_HMA: 'DD MMM YYYY hh:mm A',
  DMY_MAIN_HMA: 'DD/MM/YYYY hh:mm A',
  MDY: 'MMM DD, YYYY',
  DMY_F_SLASH: 'DD/MM/YYYY',
  MDY_F_SLASH: 'MM/DD/YYYY',
  DMY_hMA: 'DD MMM YYYY h:mm A',
};